<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="动作评估报告"
                left-arrow
                @click-left="$router.go(-1)"
        />
<!--        <Divider :style="{ color: '#767676', borderColor: '#e0e0e0', padding: '0 16px' }" content-position="left"><h3  class="stitle">{{data.name}}</h3></Divider>-->
        <Divider :style="{ color: '#767676', borderColor: '#e0e0e0', padding: '0 16px' }" content-position="left"><h3  class="stitle">{{data.video_name}}</h3></Divider>
        <Divider :style="{ color: '#767676', borderColor: '#e0e0e0', padding: '0 16px' }" content-position="left"><h3  class="stitle">动作视频</h3></Divider>
<!--        <p  style="white-space: pre-line;padding-left: 1em;padding-right: 1em">{{exeInfo.steps.replaceAll('&lt;br>','')}}</p>-->
<!--        <Divider :style="{ color: '#767676', borderColor: '#e0e0e0', padding: '0 16px' }" content-position="left"><h3  class="stitle">训练教程</h3></Divider>-->
<!--        <div v-if="exeInfo.videoInfos.length > 0">-->
            <div v-if="data.video_url  != null">
                <video controls="controls"  width='100%;' padding-top='-6px' id="videoInfo" :src="data.video_url">
                    <source type="video/mp4"/>
                </video>
            </div>
<!--        </div>-->
        <Divider :style="{ color: '#767676', borderColor: '#e0e0e0', padding: '0 16px' }" content-position="left"><h3  class="stitle">评估报告</h3></Divider>
        <div style="margin-left: 2em;margin-right: 2em;text-indent:2em;font-size:13px">
            <div  v-if="data.report_text != null && data.report_text!= ''">
                {{data.report_text}}
            </div>
            <div  v-if="data.report_text == null || data.report_text== ''">
                暂无报告
            </div>
        </div>
        <Divider  v-if="data.img_info != null && data.img_info!=''" :style="{ color: '#767676', borderColor: '#e0e0e0', padding: '0 16px' }" content-position="left"><h3  class="stitle">评估图片</h3></Divider>
        <div v-if="data.img_info != null && data.img_info!=''">
            <vimg
                    width="100%"
                    :src="data.img_info"
            />
        </div>

        <RLFooter/>
    </div>
</template>

<script>

    import {Divider,Image as vimg} from 'vant';
    import {getIdentifyReport} from '@/api/identify';
    // import {getBaseInfo} from '@/api/patient/baseinfo';
    // import {setToken,removeToken} from '@/utils/auth';
    import {getUser} from '@/api/user';

    export default {
        name: 'QueIdx',
        components: {
            Divider,vimg
        },
        data(){
            return {
                active:0,
                followcrfs:[],
                nodata:true,
                user:{
                    userName:'',
                    nickName:'',
                    idCard:'',
                    phone:'',
                    gender:'1',
                    birthday:'',
                    id:'',
                    pid:''
                },
                user_empty:true,
                follow_time:null,
                is_diary:true,
                typeHash:{},
                crfsHash:{},
                activeName:'-1',
                id:null,
                video_name:null,
                data:null,

            };
        },
        computed:{

        },
        methods:{
            showIdentifyReport(idx){
                // this.$router.push({path: '/followcrf/'+idx.id});
                this.$router.push({path: '/identify_report/',query:{id:idx.id,video_name:idx.video_name}});
            },
            formaterState(fins){
                if(fins==1){
                    return '未完成';
                }else{
                    return '已完成';
                }
            },
            formaterTime(ctime,uptime){
                let res="";
                if(ctime){
                    res+="创建时间："+this.parseTime(ctime);
                }
                if(uptime){
                    res+="    更新时间："+this.parseTime(uptime);
                }
                return res;
            },
            addIdentify(){
                this.$router.push({path: '/upload_identify/',query:{}});
            },

            initData(){
                let query = this.$route.query;
                this.id=query.id;
                this.video_name=query.video_name;
                getIdentifyReport({id:this.id,video_name:this.video_name}).then((response) => {
                    console.log(response)
                    if(response.code==200 ){
                        this.data = response.data

                    }
                });
                // let follow_time=this.parseTime(new Date(),'{y}-{m}-{d}');

            },
        }
        ,
        created() {
            //生产环境
            // var query = this.$route.query;
            // if(query.code || this.getQueryString('code')){
            //     // console.log("code")
            //     // console.log(query.code)
            //     // console.log(this.getQueryString('code'))
            //     //this.$store.commit('updateOpenid','123456');
            //     this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
            //     setToken(query.code?query.code : this.getQueryString('code'));
            //     getUser().then((res) => {
            //         console.info(res.data);
            //
            //         if(res.data){
            //             this.$store.commit('updateOpenid',res.data.openid);
            //             this.$store.commit('updatePid',res.data.id);
            //             this.$store.commit('updateBaseInfo',res.data);
            //             if(res.data.name !=''){
            //                 this.substatus = true
            //             }
            //             this.user.id=res.data.id;
            //             this.user.name=res.data.name;
            //             this.user.birthday=res.data.birthday;
            //             // this.user.idCard=response.data.idCard;
            //             this.user.telephone=res.data.telephone;
            //             this.user.gender=res.data.gender+'';
            //             this.user.address=res.data.address;
            //             this.user.detail_address=res.data.detail_address;
            //             // this.initBaseInfo();
            //             this.user_empty = false
            //             this.initData()
            //
            //         }
            //     });
            //     //getBaseInfo({}).then((response) => {
            //     //   console.info(response);
            //     // });
            // }else{
            //     removeToken();
            // }
            // 开发环境
            getUser().then((res) => {
                console.info(res.data);

                if(res.data){
                    this.$store.commit('updateOpenid',res.data.openid);
                    this.$store.commit('updatePid',res.data.id);
                    this.$store.commit('updateBaseInfo',res.data);
                    if(res.data.name !=''){
                        this.substatus = true
                    }
                    this.user.id=res.data.id;
                    this.user.name=res.data.name;
                    this.user.birthday=res.data.birthday;
                    // this.user.idCard=response.data.idCard;
                    this.user.telephone=res.data.telephone;
                    this.user.gender=res.data.gender+'';
                    this.user.address=res.data.address;
                    this.user.detail_address=res.data.detail_address;
                    // this.initBaseInfo();
                    this.user_empty = false

                    this.initData();

                }
            });
            // this.initBaseInfo();
            /*初始化列表*/
            // this.initidx();

        },
    }
</script>

<style scoped>

    .is_completed{
        color: #1bde1a;
        /*color: aqua;*/
    }
    .is_uncompleted{
        color:red;
    }

</style>
