import request from '@/utils/request'

//  获取动作评估列表
export function getIdentify(params) {
    params.dataType='noKey';
    return request({
        url: '/patient_identify_videos/identify_video_list.json',
        method: 'get',
        params:params
      })
  }

//  获取动作评估报告
export function getIdentifyReport(params) {
    params.dataType='noKey';
    return request({
        url: '/patient_identify_videos/app_identify_report.json',
        method: 'get',
        params:params
      })
  }


//保存体检指标提醒
export function saveTjzbAttention(params,data){
    params.dataType='noKey';
    return request({
        url: '/examination_attentions/save_attention.json',
        method: 'post',
        params:params,
        data:data
    })
}
